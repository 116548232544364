import React from "react";
import Travelconcept from "../../assets/images/travel-concept-with-landmarks 1.png";
import Mohit from "../../assets/images/Ellipse 296 (1).png";
import Ankur from "../../assets/images/Ellipse 296 (2).png";
import Rahul from "../../assets/images/Ellipse 296 (3).png";
import Company1 from "../../assets/images/company1.png";
import Company2 from "../../assets/images/companies2.png";
import Company3 from "../../assets/images/companies3.png";
import Company4 from "../../assets/images/comapnies4.png";
import Company5 from "../../assets/images/companies5.png";
import Company6 from "../../assets/images/companies6.png";
import Company7 from "../../assets/images/companies7.png";
import Company8 from "../../assets/images/companies8.png";
import bgpink from "../../assets/images/bgpink.png";
import bgpinkchild from "../../assets/images/bgpinkchild.png";

import "./aboutus.css";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";

function Aboutus() {

  return (
    <>
      <RedirectUrlAfterLogin />
      <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}>
        <img src={Travelconcept} alt="About Us Page" className="bannerimg" />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: "center",
            width: "90%",
          }}
        >
          <h2 style={{ fontSize: "4vw" }} className="for_aboutus">
            About Us
          </h2>
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: "5vw" }}>
        <div className="row align-items-start ">
          <div className="col-lg-6 col-md-12">
            <div
              style={{ position: "relative", maxWidth: "100%", height: "auto" }}
            >
              <div
                style={{
                  position: "relative",
                  maxWidth: "90%",
                  height: "auto",
                }}
                className="for_mainbgpink"
              >
                <img
                  src={bgpink}
                  alt="Rectangle"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  className="for_bgpink"
                />
              </div>
              <div>
                <img
                  src={bgpinkchild}
                  alt="aboutusp"
                  style={{
                    position: "absolute",
                    top: "54.2%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    textAlign: "center",
                    width: "90%",
                  }}
                  className="for_bgpinkchild"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 h-100">
            <div>
              <h1
                style={{
                  textAlign: "justify",
                  fontWeight: "bold",
                }}
                className="for_loremhead"
              >
                About Us:
              </h1>
              <p style={{ textAlign: "justify" }} className="for_loremtext">
                Welcome to Glabol India, your go-to destination for
                extraordinary travel experiences! We're not just a travel
                company; we're a modern travel community that crafts seamless
                and exciting journeys both within India and around the world.
                Whether you're a solo adventurer or prefer the company of
                like-minded individuals, we've got the perfect travel packages
                for you. Discover the joy of hassle-free travel as we design the
                best itineraries, covering everything from road trips and
                trekking expeditions to corporate outings and personalized
                tours. Our focus is on creating a sense of community among
                travellers, fostering connections that make your journey
                unforgettable. At Glabol India, we believe in offering top-notch
                accommodation, guided sightseeing, and an overall amazing
                experience—all while keeping your budget intact. Just share your
                dates with us, and get ready for a travel adventure like never
                before. Say goodbye to worries and hello to the thrill of
                exploration with Glabol India. Your dream journey awaits!
              </p>
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="container py-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 h-100">
            <div>
              <h1 className="fw-bold text-center mb-4">Why Glabol?</h1>
              <p className="text-justify">
                Choosing Glabol for your travel adventures means opting for an
                unparalleled experience that goes beyond traditional tourism. At
                Glabol, we pride ourselves on being more than just a travel
                company—we're a vibrant travel community focused on creating
                memories that last a lifetime. Here's why Glabol stands out:
              </p>
            </div>
            <ol className="mt-4">
              <li className="mb-3">
                <strong>Community Connection:</strong> We believe in the power
                of shared experiences. Our trips are designed to bring
                like-minded individuals together, fostering a sense of
                camaraderie and friendship among travellers.
              </li>
              <li className="mb-3">
                <strong>Diverse Itineraries:</strong> Whether you're a
                thrill-seeker, nature enthusiast, or looking for a relaxing
                getaway, Glabol offers a variety of itineraries to suit every
                traveller's taste. From road trips and trekking expeditions to
                corporate outings and personalized tours, we've got it all.
              </li>
              <li className="mb-3">
                <strong>Seamless Planning:</strong> Say goodbye to travel
                headaches. Our team meticulously plans each journey, ensuring
                hassle-free travel, top-notch accommodation, and guided
                sightseeing. Just provide us with your dates, and we'll take
                care of the rest.
              </li>
              <li className="mb-3">
                <strong>Budget-Friendly:</strong> Traveling with Glabol won't
                break the bank. We're committed to providing outstanding
                experiences without burning a hole in your pocket. Enjoy the
                best accommodations and activities at affordable prices.
              </li>
              <li className="mb-3">
                <strong>Tailored Experiences:</strong> Your journey, your way.
                We understand that every traveller is unique, so we offer
                customized tour packages to cater to your specific preferences
                and interests.
              </li>
            </ol>
            <p className="text-center mt-4">
              So, why Glabol? Because we're not just about travel; we're about
              creating connections, making memories, and ensuring that every
              trip with us is an adventure to remember. Join us on a journey
              that transcends the ordinary and discover the world in a whole new
              way with Glabol.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <h1 className="text-center my-4 font-weight-bold for_team pt-5">
          <span style={{ color: "rgb(247, 37, 72)" }}>Our</span> Team
        </h1>

        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow">
                <img
                  src={Rahul}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Vikas</h5>
                <p className="card-text forfounders">CEO, FOUNDER</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow">
                <img
                  src={Ankur}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Vikas</h5>
                <p className="card-text forfounders">CEO, FOUNDER</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow">
                <img
                  src={Mohit}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Rahul</h5>
                <p className="card-text forfounders2">Head of Operations</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow for_shadow2">
                <img
                  src={Ankur}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Vikas</h5>
                <p className="card-text forfounders">CEO, FOUNDER</p>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-center my-4 font-weight-bold for_team pt-5">
          <span style={{ color: "rgb(247, 37,72)" }}>Our</span> Partners
        </h1>

        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company1}
                className="img-fluid mb-2 for_companyimg forsmallcopyimg"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company2}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company3}
                className="img-fluid mb-2 for_companyimg forsmallcopyimg"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company4}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company5}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company6}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company7}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company8}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Aboutus;
