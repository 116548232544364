import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Noimg from "../../assets/images/No-imag.jpg";
import hardimg from "../../assets/images/adventurehard.jpg";
import Label2Image from "../../assets/images/Label2image.png";
import Swal from "sweetalert2";
import { Loader } from "../loader/Loader";
import { gtag_report_conversion, useAppDispatch } from "../../hook";

const AdventurePage = () => {
  const [load, setLoad] = useState<boolean>(true);

  const location = useLocation();
  const id = location?.state?.adven_id; // Adventure ID (if selected)
  const period = location?.state?.period; // Period ID (the selected month)
  const navigate = useNavigate();
  const [adventure, setAdventure] = useState<any[]>([]); // Adventure data array
  const [adventurety, setAdventurety] = useState<any>(); // Single adventure type data
  const [mnth, SetPmonth] = useState<string | null>(null); // Single adventure type data

  // Month mapping to convert number to month name
  const monthNames: { [key: number]: string } = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const [cardsData, setCardsData] = useState<any>([]);
  const [emailError, setEmailError] = useState("");
  const dispatch = useAppDispatch();
  const [mobileError, setMobileError] = useState("");
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpData, setOtpData] = useState();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    numberOfTraveller: "",
    mobile: "",
    email: "",
    traveler_month: "",
    destination: "",
    destination_id: id,
    otp: ""

  });
  const resetFormData = () => {
    setFormData({
      name: "",
      numberOfTraveller: "",
      mobile: "",
      email: "",
      traveler_month: "",
      destination: "",
      destination_id: id,
      otp: ""
    });
  };
  const showLoader = (text: string) => {
    Swal.fire({
      text: text,
      allowOutsideClick: false,
      showConfirmButton: false,
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      destination: cardsData.title,
    }));
  };
  const searchtour = async () => {
    try {
      const payload: any = {};

      if (id) {
        payload.adventure_type = id;
      } else {
        console.warn("adventureid (id) is missing");
      }

      if (period) {
        payload.period = period;
      } else {
        console.warn("period is missing");
      }

      console.log("Final Payload before API call:", payload); // Debug payload

      setLoad(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/search-tour`,
        payload
      );

      if (response.data.success) {
        setLoad(false);

        setAdventure(response?.data.getData || []); // Set adventure data array
        if (response.data.adventureTypeData) {
          console.log(
            "Adventure Type Data:",
            response?.data?.adventureTypeData
          );
          setAdventurety(response?.data?.adventureTypeData);
        }

        if (response.data.month) {
          // Convert month number to month name
          const monthNumber = response.data.month;
          SetPmonth(monthNames[monthNumber] || null); // Set the month name or null if not found
        }
      } else {
        setLoad(false);
        navigate("/home");
        Swal.fire({
          icon: "info",
          title: "No Packages Found",
        });
      }
    } catch (error) {
      setLoad(false);
      console.error("Error fetching adventure data:", error);
    }
  };

  useEffect(() => {
    searchtour();
  }, []);

  const isFormValid = () => {
    return Object.values(formData).every((value) => value !== "");
  };

  const handleVerifyOtp = async () => {
    if (formData.otp) {
      const response = await verifyOtp(formData.otp);

      if (response.success) {
        Swal.fire({
          title: 'Success!',
          text: 'OTP verified and enquiry sent successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          resetFormData();
        });

      } else {
        Swal.fire({
          title: 'Invalid OTP!',
          text: response.message || 'The OTP you entered is incorrect. Please try again.',
          icon: 'error',
          confirmButtonText: 'Retry',
        });
      }
    } else {
      Swal.fire({
        title: 'Oops!',
        text: 'Please enter the OTP.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };
  const sendOtpEnquiry = async (mobile: string) => {
    showLoader("Sending OTP Please Wait");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendOtpForEnquiry`,
        { mobile }
      );
      console.log("OTP Sent Successfully:", response.data);
      setOtpData(response.data.otp._id);

    } catch (error) {
      console.error("Error sending OTP:", error);
      setMobileError("Failed to send OTP. Please try again.");
    }
  };
  const verifyOtp = async (otp: string): Promise<{ success: boolean; message: string }> => {
    showLoader("Sending enquiry Please Wait");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/verifyOtpForEnquiry?id=${otpData}`,
        {
          otp: formData.otp,
          name: formData.name,
          destination: formData.destination,
          numberOfTraveller: formData.numberOfTraveller,
          mobile: formData.mobile,
          email: formData.email,
          traveler_month: formData.traveler_month,
        }
      );

      // Assuming the API response contains `success` and `message`
      return response.data;
    } catch (error) {
      // Handle the error and return a failure response
      return {
        success: false,
        message: 'Failed to verify OTP. Please try again.',
      };
    }
  };
  const sendOtp = async () => {
    if (formData.mobile.length === 10) {
      try {
        await sendOtpEnquiry(formData.mobile);

        // Show success message using SweetAlert
        Swal.fire({
          title: 'OTP Sent!',
          text: `An OTP has been sent to your mobile number ${formData.mobile}.`,
          icon: 'success',
          confirmButtonText: 'OK',
        });

        setOtpVisible(true);
      }
      catch (error) {
        console.error("Error in sendOtp function:", error);

        // Show error message using SweetAlert
        Swal.fire({
          title: 'Error',
          text: 'Failed to send OTP. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } else {
      setMobileError("Please enter a valid mobile number");
      setOtpVisible(false);

      // Show validation error using SweetAlert
      Swal.fire({
        title: 'Invalid Number',
        text: 'Please enter a valid 10-digit mobile number.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };
  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <div className="personalized_img">
            <img src={hardimg} className="img-fluid for_featuredimg" />

            <div className="for_featured_under">
              <p className="for_personalizedtext">
                {adventurety?.adventure_type && mnth
                  ? `${adventurety.adventure_type} - ${mnth}` // Show both adventure type and month
                  : adventurety?.adventure_type || mnth}{" "}
              </p>
            </div>

            <div className="container">
              <div
                style={{ marginTop: "-100px" }}
                className="for-mobile-enquriy-form"
              >
                <div className="diva"></div>
                <div className="container finalWidthfeature">
                  <div className="form-container">
                    <div className="row mt-2 justify-content-center p-3 for_smalldevice">
                      <h3
                        style={{ fontWeight: "700" }}
                        className="text-center perfecttrip"
                      >
                        Not sure what to do? We’ll give you a Call back
                      </h3>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Enter your name"
                          name="name"
                          value={formData.name}

                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Enter your mail id"
                          name="email"
                          value={formData.email}

                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Number of travellers"
                          name="numberOfTraveller"
                          value={formData.numberOfTraveller}

                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Enter Your Destination"
                          name="destination"
                          value={formData.destination}

                          onChange={handleChange}
                        />
                      </div> <div className="col-12 col-lg-4 col-md-12 p-2">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control placeholderclass"
                            placeholder="Enter your phone number"
                            name="mobile"
                            value={formData.mobile}
                              onInput={(e: any) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                              }}
                              maxLength={10}
                            onChange={handleChange}
                          />
                          <button
                            type="button"
                            onClick={sendOtp}
                            className="btn btn-primary"
                            disabled={formData.mobile.length !== 10}
                            style={{
                              padding: "5px 10px",
                              fontSize: "10px",
                              backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                              borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                              cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                            }}
                          >
                            Send OTP
                          </button>
                        </div>
                        {otpVisible && (
                          <div className="mt-3 w-50 m-auto">
                            <input
                              type="text"
                              className="form-control"
                              name="otp"
                              placeholder="Enter OTP"
                              maxLength={4}
                              value={formData.otp}
                              onChange={handleChange}
                              style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <select
                          className="form-control placeholderclass form-select"
                          name="traveler_month"
                          value={formData.traveler_month}

                          onChange={handleChange}
                        >
                          <option value="">Select Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 col-md-12 mt-2 featuresubmit">
                        <button
                          className="for_btn"
                          onClick={handleVerifyOtp}
                          style={{
                            backgroundColor: isFormValid() ? "#FFA923" : "gray" , 
                            cursor: isFormValid() ? "pointer" : "not-allowed",
                          }}
                          disabled={!isFormValid()} 
                        >
                          Submit
                        </button>
                      </div>

                    </div>
                  </div>
                  <div className="accordion-container">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Not sure what to do? We’ll give you a Call back
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Enter your name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Enter your mail id"
                              name="email"
                              value={formData.email}

                              onChange={handleChange}
                            />
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Number of travellers"
                              name="numberOfTraveller"
                              value={formData.numberOfTraveller}

                              onChange={handleChange}
                            />

                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Enter Your Destination"
                              name="destination"
                              value={formData.destination}
                              onChange={handleChange}
                              readOnly
                            />
                            <select
                              className="form-control placeholderclass form-select"
                              name="traveler_month"
                              onChange={handleChange}
                            >
                              <option value="">Select Month</option>
                              <option value="January">January</option>
                              <option value="February">February</option>
                              <option value="March">March</option>
                              <option value="April">April</option>
                              <option value="May">May</option>
                              <option value="June">June</option>
                              <option value="July">July</option>
                              <option value="August">August</option>
                              <option value="September">September</option>
                              <option value="October">October</option>
                              <option value="November">November</option>
                              <option value="December">December</option>
                            </select>
                            <div style={{ position: "relative", width: "100%" }}>
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter your phone number"
                                  name="mobile" onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                  }}
                                  maxLength={10}
                                value={formData.mobile}
                                onChange={handleChange}
                                style={{ paddingRight: "100px" }} // Adjust padding to make space for the button
                              />
                              <button
                                type="button"
                                onClick={sendOtp}
                                className="btn btn-primary"
                                disabled={formData.mobile.length !== 10}
                                style={{
                                  position: "absolute",
                                  right: "10px", // Adjust for spacing
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  padding: "5px 10px",
                                  fontSize: "10px",
                                  backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                  borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                  cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                                }}
                              >
                                Send OTP
                              </button>
                              {otpVisible && (
                                <div
                                  className="mt-3 w-50 m-auto"
                                  style={{
                                    position: "absolute",
                                    top: "100%",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    marginTop: "10px", // Space between fields
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="otp"
                                    placeholder="Enter OTP"
                                    value={formData.otp}
                                    maxLength={4}
                                    onChange={handleChange}
                                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                                  />
                                </div>
                              )}
                            </div>

                            <button
                              className="for_btn mt-5"
                              style={{
                                position: "relative",
                                top: "100%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                marginTop: "10px",
                                backgroundColor: isFormValid() ? "#FFA923" : "gray",
                                cursor: isFormValid() ? "pointer" : "not-allowed",
                              }}
                              onClick={handleVerifyOtp}
                              disabled={!isFormValid()}
                            >
                              Submit
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divc2"></div>
              </div>
            </div>
          </div>
          <div className="container my-3">
            <div className="row mt-4">
              <div className="hr_featured mb-2"></div>
              <h1 className="text-center for_backpacking mt-4">
                <span className="pink-text">Featured </span>
                <span className="black-text"> Packages</span>
              </h1>

              {/* Map over the adventure array to display each package */}
              {adventure.map((tour) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2 mt-4"
                  key={tour._id}
                >
                  <div
                    className="card border-0 for_card_inter mb-1"
                    onClick={() => {
                      const id = tour._id;
                      const slugUrl = tour?.title

                        ?.toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/\//g, "-")
                        .replace(/\((.*?)\)/g, "$1");
                      navigate(`/tripdetails/${slugUrl}`, {
                        state: id,
                      });
                    }}
                  >
                    <div className="card border-0 for_card_inter mb-1">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${tour.tour_image?.[0]?.src || ""
                          }`}
                        alt={tour.title || "Featured Package"}
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          if (target) {
                            target.src = Noimg;
                          }
                        }}
                        className="card-img-top-inter2 card-img2"
                      />

                      <div className="for_visitdays mb-4">
                        <h5 className="for_h5">
                          {tour.tourtype === "normal" ? "Group" : tour.tourtype}
                        </h5>
                      </div>
                      {tour?.tagLabelNames?.[0] && (
                        <div className="for-sold-out2 mb-4">
                          <img src={Label2Image} className="img-fluiddd" />
                          <div className="LabelName">
                            {tour.tagLabelNames[0]}
                          </div>
                        </div>
                      )}

                      <div className="dayandnightw">
                        <p>
                          {tour.nightorday}N/{tour.nightorday + 1}D
                        </p>
                      </div>

                      <div className="card-body">
                        <div className="card-text">
                          <div className="main_para justify-content-between">
                            <h5 className="loremforfeatured">{tour.title}</h5>
                            {tour.location && tour.location !== "undefined" && (
                              <p className="fetured_para">
                                {tour.location
                                  ?.split(" ")
                                  .slice(0, 4)
                                  .join(" ")}
                              </p>
                            )}
                          </div>
                          <div className="d-flex justify-content-between align-items-baseline mt-2">
                            {tour.packageType_price &&
                              tour.packageType_price !== "undefined" && (
                                <p className=" startrate ">
                                  {tour.tourtype === "normal"
                                    ? `Start from ₹${tour?.packageType_price}`
                                    : "Customizable Price"}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdventurePage;
