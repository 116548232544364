import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "react-bootstrap";
import Smilygirl from "../../assets/images/Smilygirl.png";

import axios from "axios";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../home/home.css";
import Swal from "sweetalert2";
import Flight from "../../assets/images/flight.png";
import Yellogirl from "../../assets/images/yellogirl.png";

import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";

import Familytrip from "../../assets/images/Familytrips.png";
import collegetrip from "../../assets/images/Collegetrips.png";
import corporate from "../../assets/images/corporatetrips.png";
import solotrip from "../../assets/images/Solotrip (2).png";
import Backyellow from "../../assets/images/backyellow.png";
import News from "../../assets/images/news.png";
import subtract22 from "../../assets/images/subtract22.png";
import suraj from "../../assets/images/surajj.png";
import anil from "../../assets/images/anill.png";
import chinmayee from "../../assets/images/chreeee.png";
import "./home.css";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore from "swiper";
import { useParams } from "react-router-dom";
import { Loader } from "../loader/Loader";
import Noimg from "../../assets/images/No-imag.jpg";
import { useDispatch } from "react-redux";
import { searchTour } from "../../Reducers/searchTourSlice";
import { gtag_report_conversion } from "../../hook";

function Home() {
  const dispatch = useDispatch<any>();

  const currentUrl = window.location.pathname;

  localStorage.setItem("redirectUrl", currentUrl);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/blogpage");
  };
  const [isExpanded, setIsExpanded] = useState(false);

  interface Item {
    image: { src: string; orgName: string }[];
    title: string;
    nightorday: string;
    tagline: string;
    bookPrice: number;
    tour_image: { orgName: string }[];
    tourData: [
      {
        tagline: string;
        title: string;
        nightorday: string;
        tour_image: { src: string }[];
        packageType_price: string;
        orgName: string;
        _id: string;
        src: string;
        overview: string;
      }
    ];
    packageType_price: string;
    id: number;
    _id: number;
    src: string;
    menu: string;
  }

  interface Trip {
    Datedata: {
      start_date?: string[];
      end_date?: string[];
    };
    _id?: string;
    title?: string;
    tagline: string;
    tour_image?: {
      src?: string;
      fileName?: string;
      orgName?: string;
    }[];
    location?: string;
    nightdaye?: number;
    startDate?: string[];
    endDate?: string[];
  }

  const [category, setCategory] = useState<Item[]>([]);
  const [adventData, setAdventData] = useState<Item[]>([]);

  const [data, setData] = useState<Item[]>([]);
  const [inteData, setInteData] = useState<Item[]>([]);
  const [backpacData, setBackpacData] = useState<Item[]>([]);
  const [weekData, setWeekData] = useState<Item[]>([]);
  const [personalizedData, setPersonalizedData] = useState<Item[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [trackingData, setTrackingData] = useState<Item[]>([]);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [futureMonths, setfutureMonths] = useState<any>();
  const [filteredUpcomingTrips, setFilteredUpcomingTrips] = useState<any>(null);
  const [isHorizontal, setIsHorizontal] = useState(true);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [upcomData, setUpcomData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [featuredData, setFeaturedData] = useState({
    menumaster_id: "",
    adventure_type: "",
    period: "",
    // image: "",
  });

  function getUniqueStartMonthsFromTrips(trips: Trip[]) {
    const uniqueMonthsYears = new Set<string>();
    const currentDate: any = new Date();
    const currentMonthYear = currentDate.toLocaleString("en-us", {
      month: "long",
      year: "numeric",
    });

    const monthYearToDate = (monthYear: string) => {
      const [month, year] = monthYear.split(" ");
      return new Date(`${month} 1, ${year}`);
    };

    trips.forEach((trip: any) => {
      trip.startDate.forEach((date: any) => {
        const tripStartDate = new Date(date);
        if (tripStartDate > currentDate) {
          const monthYear = tripStartDate.toLocaleString("en-us", {
            month: "long",
            year: "numeric",
          });
          uniqueMonthsYears.add(monthYear);
        }
      });
    });

    const sortedMonths = Array.from(uniqueMonthsYears).sort((a, b) => {
      return monthYearToDate(a).getTime() - monthYearToDate(b).getTime();
    });

    setfutureMonths(sortedMonths);
    const currentIndex = sortedMonths.findIndex(
      (monthYear) => monthYear === currentMonthYear
    );
    setSelectedMonth(sortedMonths[currentIndex] || sortedMonths[0]);

    // code for starting month filter
       let newMonth = sortedMonths[currentIndex] || sortedMonths[0];
       const [selectedMonthName, selectedYear] = newMonth.split(" ");

       const filteredTrips = trips.filter((trip: any) => {
         for (const startDate of trip.startDate) {
           const tripStartDate = new Date(startDate);
           const tripMonth = tripStartDate.toLocaleString("default", {
             month: "long",
           });
           const tripYear = tripStartDate.getFullYear().toString();

        

           if (
             tripMonth === selectedMonthName &&
             tripYear === selectedYear &&
             tripStartDate 
           ) {
             return true;
           }
         }
         return false;
       });
        console.log(selectedMonthName, selectedYear, filteredTrips, trips,"||||||||||||||||||||||||||||||||||||||||||");
        setFilteredUpcomingTrips(filteredTrips);
  }

  function formatTotalAmount(amount: any) {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return "0";
    }

    return amount.toLocaleString("en-IN");
  }

  const findcetegory = async () => {
    setLoad(true);
    const findResp = await axios.get(
      `${process.env.REACT_APP_API_URL}/getlist_master`
    );
    setCategory(findResp.data.data);
    setLoad(false);
  };

  const advantour = async () => {
    setLoad(true);
    const advantureResp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/get-all-adventure`
    );
    setAdventData(advantureResp.data.data);
    setLoad(false);
  };

  const handleImageClick = (item: Item) => {
    setSelectedImage(item);
  };

  const trackingImage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getList_window`
      );
      const data = response.data.data;
      setTrackingData(data);
      setSelectedImage(data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    trackingImage();
  }, []);

const handleFindTours = (e: any) => {
  e.preventDefault();

  // Check if no fields are selected
  if (
    !featuredData.menumaster_id &&
    !featuredData.adventure_type &&
    !featuredData.period
  ) {
    Swal.fire({
      icon: "info",
      title: "Please select at least one field",
    });
    return;
  }

  // If only the period (month) is selected, navigate with period in state
  if (
    !featuredData.menumaster_id &&
    !featuredData.adventure_type &&
    featuredData.period
  ) {
    navigate("/adventure-page", {
      state: {
        period: featuredData.period, // Use the selected month (period)
      },
    });
    return;
  }

  // If only adventure type is selected, navigate to a new page with image and title
  if (
    !featuredData.menumaster_id &&
    featuredData.adventure_type &&
    !featuredData.period
  ) {
    navigate("/adventure-page", {
      state: {
        menu_id: featuredData.menumaster_id,
        adven_id: featuredData.adventure_type,
      },
    });
    return;
  }

  // NEW CONDITION: If adventure type and period are selected
  if (
    !featuredData.menumaster_id &&
    featuredData.adventure_type &&
    featuredData.period
  ) {
    const searchTourData = {
      adventure: featuredData.adventure_type,
      period: featuredData.period,
    };

    dispatch(searchTour(searchTourData)).then((res: any) => {
      if (res.payload.success) {
        navigate("/adventure-page", {
          state: {
            adventure_type: featuredData.adventure_type,
            period: featuredData.period,
          },
        });
      } else {
        Swal.fire({
          icon: "info",
          title: "No Packages Found",
        });
      }
    });
    return;
  }

  // Create searchTourData to include all fields
  const searchTourData = {
    category: featuredData.menumaster_id || "", // Ensure default values
    adventure: featuredData.adventure_type || "",
    period: featuredData.period || "", // Ensure period is included
  };

  // Dispatch searchTour action and handle response
  dispatch(searchTour(searchTourData)).then((res: any) => {
    if (res.payload.success) {
      const slug: any = res?.payload?.menumasterData?.title
        ?.toLowerCase()
        .replace(/\s+/g, "-");

      navigate(`/featured-package/${slug}`, {
        state: {
          adventure_type: "",
          itemid: featuredData.menumaster_id,
          period: featuredData.period || "",
        },
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "No Packages Found",
      }).then(() => {
        resetFormData();
      });
    }
  });
};



  const resetFormData = () => {
    setFeaturedData({
      menumaster_id: "",
      adventure_type: "",
      period: "",
    });
  };

  function handleFindTours2(event: any) {
    event.preventDefault();
    const section = document.getElementById("personalizedSection");
    if (section !== null) {
      const sectionPosition =
        section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionPosition - 55;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  const fetchUpcomingData = async (month: string = "", year: string = "") => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/showupcomingTrip_10?month=${month}&year=${year}`
      );
      setUpcomData(response.data.upcomingTrips);
      
      setFilteredUpcomingTrips(response.data.upcomingTrips);
      getUniqueStartMonthsFromTrips(response.data.upcomingTrips);
    } catch (error) {
      console.error("Error fetching upcoming trip data:", error);
    }
    setLoading(false);
  };
  const handleMonthChange = (selectedMonth: string) => {
    const [selectedMonthName, selectedYear] = selectedMonth.split(" ");

    const filteredTrips = upcomData.filter((trip: any) => {
      for (const startDate of trip.startDate) {
        const tripStartDate = new Date(startDate);
        const tripMonth = tripStartDate.toLocaleString("default", {
          month: "long",
        });
        const tripYear = tripStartDate.getFullYear().toString();

        if (tripMonth === selectedMonthName && tripYear === selectedYear) {
          return true;
        }
      }
      return false;
    });

    setFilteredUpcomingTrips(filteredTrips);
    setSelectedMonth(selectedMonth);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsHorizontal(window.innerWidth >= 992);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    fetchUpcomingData();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bpacktri = async () => {
    setLoad(true);
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getBacgpackingList`
      );
      setBackpacData(resp.data.data);
    } catch (error: any) {
      setError(error);
    }
    setLoad(false);
  };

  const weekendata = async () => {
    setLoad(true);
    try {
      const weektdata = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getAllWeekndeTripList`
      );
      setWeekData(weektdata.data.data);
    } catch (error: any) {
      setError(error);
    }
    setLoad(false);
  };

  const interapidata = async () => {
    setLoad(true);
    const interRsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/getAllInternationalTripList`
    );
    setInteData(interRsp.data.data);
    setLoad(false);
  };

  const personaldata = async () => {
    setLoad(true);
    const personResp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/get-alltourpackageCustomized`
    );
    setPersonalizedData(personResp.data.data);
    setLoad(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAllList_1`
        );
        setData(response.data.data);
      } catch (error: any) {
        setError(error);
      }
      setLoad(false);
    };
    fetchData();

    return () => {};
  }, []);

  useEffect(() => {
    findcetegory();
    advantour();
    // trackingImage();
    bpacktri();
    weekendata();
    interapidata();
    personaldata();
  }, []);

  const swiperParams = {
    slidesPerView: 1,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: 0,
    breakpoints: {
      480: {
        slidesPerView: 1.5,
        spaceBetween: 0,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    },
  };

  const swiperParams2 = {
    slidesPerView: 3,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: -5,
    breakpoints: {
      480: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: -2,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: -2,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: -2,
      },
      1200: {
        slidesPerView: 7,
        spaceBetween: -2,
      },
      1400: {
        slidesPerView: 7,
        spaceBetween: -2,
      },
    },
  };
  const swipertestimonial = {
    slidesPerView: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    spaceBetween: 2,
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 2,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 2,
      },
      992: {
        slidesPerView: 1,
        spaceBetween: 2,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 2,
      },
    },
  };

  const verticleswiper2 = {
    slidesPerView: 2,
    spaceBetween: 2,
    breakpoints: {
      576: {
        slidesPerView: 3,
      },
    },
    768: {
      slidesPerView: 3,
    },
  };
  SwiperCore.use([Navigation]);

  const [swiper, setSwiper] = useState<any>(null);
  const [swiper1, setSwiper1] = useState<any>(null);

  return (
    <>
      {load ? <Loader /> : ""}

      <div className="parent">
        <div className="diva"></div>
        <div className="container finalWidth">
          <div className="row d-flex for_lefttextt" style={{ height: "100%" }}>
            <div className="col-sm-12 col-lg-5 col-12 col-md-12 text-justify for_pbb1 pb-3">
              <h3
                className="text-start pt-5 pding_2"
                style={{ color: "#DC1B5E", fontWeight: "700" }}
              >
                BEST DESTINATIONS IN INDIA{" "}
              </h3>
              <h1
                className="for_heading text-start"
                style={{ fontWeight: "700" }}
              >
                Travel, Enjoy and Live a New and Full Life
              </h1>
              <p className="for_first_about pt-1">
                Embark on an unforgettable journey with our travel website and
                discover the world's most enchanting destinations
              </p>
              <div className="for_btn_btn pt-1">
                <a
                  href="/featured-package?source=featuredTour&id=${item._id}"
                  className="for_find_btn"
                  onClick={handleFindTours2}
                >
                  FIND OUT MORE
                </a>
              </div>
            </div>
            <div
              className="col-sm-12 col-lg-7 col-12 col-md-12 formd"
              style={{
                background: "rgba(255, 234, 201, 1)",
                paddingBottom: "85px",
                paddingTop: "20px",
              }}
            >
              <img
                src={Smilygirl}
                className="img-fluid pt-2"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div className="divc "></div>
      </div>
      <div className="container position-relative">
        <div className="parent2 p-2" style={{ marginTop: "-100px" }}>
          <div className="diva"></div>
          <div className="container-fluid finalWidth2 mt-2">
            <div
              style={{
                boxShadow: "0px 0px 10px 1px #00000040",
                width: "100%",
                borderRadius: "10px",
                border: "none",
                background: "white",
              }}
              className="for_secondboxx "
            >
              <h3
                style={{ fontWeight: "700" }}
                className="text-center perfect_trip d-flex-justify-content-center align-items-center"
              >
                Find perfect trip for yourself
              </h3>
              <div className="row mt-1 justify-content-center for_undercontent">
                <div className="col-12 col-lg-3 p-0">
                  <select
                    className="form-select form-select-lg mb-3 for_select p-2 custom-select"
                    aria-label=".form-select-lg example"
                    value={featuredData.menumaster_id}
                    onChange={(e) =>
                      setFeaturedData({
                        ...featuredData,
                        menumaster_id: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Category</option>
                    {category.map((opts: any, i: number) => (
                      <option key={i} value={opts._id}>
                        {opts.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-lg-3 p-0">
                  <select
                    className="form-select form-select-lg mb-3 for_select p-2 custom-select"
                    aria-label=".form-select-lg example"
                    value={featuredData.adventure_type}
                    onChange={(e) =>
                      setFeaturedData({
                        ...featuredData,
                        adventure_type: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Adventure</option>
                    {adventData.map((opts: any, i: number) => (
                      <option key={i} value={opts._id}>
                        {opts.adventure_type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-lg-3 p-0">
                  <select
                    className="form-select form-select-lg mb-3 for_select p-2"
                    aria-label=".form-select-lg example"
                    value={featuredData.period}
                    onChange={(e: any) =>
                      setFeaturedData({
                        ...featuredData,
                        period: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-12 col-lg-2 p-0 find_btn">
                  <button className="find_tourbtn" onClick={handleFindTours}>
                    FIND TOURS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divc2"></div>
      </div>

      <div className="container">
        <div className="mt-5 for-featured-tours">
          <h3 className="for_headingedit">
            Featured <span className="text-dark">Tours</span>{" "}
          </h3>
          <h3 className="for_places text-center">
            Beautiful Places Around The World
          </h3>

          <div className="mt-4">
            <div className="row row-cols-2 row-cols-xl-4  row-cols-lg-3 row-cols-md-2 row-cols-sm-1 p-0">
              {data.length > 0 &&
                data.map((item: any, index: number) => (
                  <div
                    className="col d-flex justify-content-center align-items-center p-3 card-image-find-tour"
                    key={index}
                  >
                    <div
                      className="card card border-0 for_cards_featured "
                      style={{ boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.5)" }}
                      onClick={() => {
                        const slugUrl = item.title
                          ?.toLowerCase()
                          .replace(/\s+/g, "-")
                          .replace(/\//g, "-")
                          .replace(/\((.*?)\)/g, "$1");
                        navigate(`/featured-package/${slugUrl}`, {
                          state: {
                            itemid: item._id,
                            run: true,
                            // source: "featuredTour",
                          },
                        });
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${item?.image?.[0]?.src}`}
                        className="card-img-top-week"
                        alt="..."
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          if (target) {
                            target.src = Noimg;
                          }
                        }}
                      />
                      <div className="for_content_inner2">
                        <h5 className="for_cityname">
                          {item?.title.split(" ").slice(0, 1).join(" ")}
                          {item?.title.split(" ").length > 6 ? "..." : ""}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <section className="container   mt-3">
        <div className="row for_tab">
          <div className="col-12 col-sm-12 col-lg-6 col-md-6 ygirl">
            <div className="yellogirl">
              <img src={Yellogirl} className="img-fluid  px-4 " />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6 col-md-6 mt-4">
            <h3 className="mt-5 for_content px-4">
              <span style={{ color: "#DC1B5E" }}>Explore</span> all corners of
              the india with us
            </h3>
            <p className="for_content2 mt-3 px-4">
              Discover the incredible diversity of India, a land of breathtaking
              contrasts and unforgettable experiences. From the majestic
              Himalayas in the north to the serene backwaters of Kerala in the
              south, every corner of this vast country tells a unique story.
              Wander through the bustling streets of Mumbai, alive with energy,
              and soak in the rich cultural heritage of Kolkata. India offers a
              tapestry of wonders, from natural beauty to vibrant traditions,
              waiting to be explored. Let us guide you on this remarkable
              journey, where every moment promises to be an adventure you'll
              cherish forever. Experience the magic of India!
            </p>
            <div className="text-start px-3 my-4 flight_bx">
              <img src={Flight} />
            </div>
          </div>
        </div>
      </section>

      {Array.isArray(filteredUpcomingTrips) &&
        filteredUpcomingTrips.length > 0 && (
          <section className="container mt-5 for_upcoming_trips">
            <div className="">
              <h3 className="for_headingedit">
                Upcoming <span className="text-dark">Trips</span>
              </h3>
              <h4 className="for_places text-center">
                Explore Popular Recommendations
              </h4>
              <Swiper
                {...swiperParams2}
                style={{ padding: "0px 0px" }}
                modules={[Pagination]}
              >
                {Array.isArray(futureMonths) &&
                  futureMonths.map((ele, index) => {
                    const [month, year] = ele.split(" ");
                    return (
                      <SwiperSlide key={index}>
                        <button
                          className={`for_button  mt-3 mx-3 ${
                            selectedMonth === ele ? "active" : ""
                          }`}
                          onClick={() => handleMonthChange(ele)}
                          style={{
                            backgroundColor:
                              selectedMonth === ele ? "yellow" : "",
                            color: selectedMonth === ele ? "black" : "",
                          }}
                        >
                          {`${month.slice(0, 3)} ${year}`}
                        </button>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>

            <div className="container mt-3 text-center ">
              <Carousel
                responsive={responsive}
                swipeable={false}
                draggable={false}
                ssr={true}
                autoPlaySpeed={2000}
                autoPlay={true}
                infinite={true}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {filteredUpcomingTrips.map((item: any, index: number) => (
                  // <Link to={`/tripdetails/${item._id}`} key={index} >
                  <div
                    className="weekand_parent "
                    key={index}
                    onClick={() => {
                      const id = item._id;
                      const slugUrl = item?.title
                        ?.toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/\//g, "-")
                        .replace(/\((.*?)\)/g, "$1");
                      navigate(`/tripdetails/${slugUrl}`, {
                        state: id,
                      });
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item?.tour_image[0]?.src}`}
                      className="for_Cbgimgw"
                      alt={`Upcoming trip ${index}`}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        if (target) {
                          target.src = Noimg;
                        }
                      }}
                    />
                    <div className="Europe_trip">
                      <p className="europecontent">
                        {item.title.length > 16
                          ? `${item.title.slice(0, 16)}...`
                          : item.title}
                      </p>
                      <p className="europecontent2">
                        {item.nightorday} Night / {item.nightorday + 1} Days
                      </p>
                    </div>
                  </div>
                  // </Link>
                ))}
              </Carousel>
            </div>
          </section>
        )}

      <section className="container mt-1">
        <div className="trekking_headers show-mobile-view">
          <h3 className="for_treaking">EXPERIENCES WITH</h3>
          <h3 className="for_treaking2">GLABOL </h3>
        </div>
        <h4 className="for_treakingfeature show-mobile-view">
          Find your perfect place for Holidays
        </h4>
        <div className="row my-1">
          <div className="col-6 col-sm-6 col-lg-5 col-md-6 mt-3 treacking-experiences">
            <div className="trekking_headers none-mobile-view">
              <h3 className="for_treaking">EXPERIENCES WITH </h3>
              <h3 className="for_treaking2"> GLABOL </h3>
            </div>
            <h4 className="for_treakingfeature none-mobile-view">
              Find your perfect place for Holidays
            </h4>
            <p className="travel_content">{selectedImage?.tagline}</p>
            {selectedImage && (
              <div>
                <div className="for_package">
                  <p className="stit">{selectedImage?.tourData?.[0]?.title}</p>
                  <p>
                    {Number(selectedImage?.tourData?.[0]?.nightorday) + 1} Day/
                    {selectedImage?.tourData?.[0]?.nightorday} Night
                  </p>
                  <p>₹{selectedImage?.tourData?.[0]?.packageType_price}</p>
                </div>
              </div>
            )}
            <div className="for_viewmore d-flex  align-items-end">
              <button
                className="for_viewMore"
                onClick={() => {
                  const id = selectedImage?.tourData?.[0]?._id;
                  const slugUrl = selectedImage?.tourData?.[0]?.title
                    ?.toLowerCase()
                    .replace(/\s+/g, "-")
                    .replace(/\//g, "-")
                    .replace(/\((.*?)\)/g, "$1");
                  navigate(`/tripdetails/${slugUrl}`, {
                    state: id,
                  });
                }}
              >
                View More Details
              </button>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-lg-4 col-md-6 mt-3">
            <div className="ParentUdraw">
              <div className="Udraw2">
                <div className="ChildUdraw">
                  {selectedImage && (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${selectedImage?.tourData?.[0]?.tour_image?.[0]?.src}`}
                      className="img-fluid Under_img"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-3 col-12 col-sm-12 col-md-6 col-lg-3 ver_swiper d-flex justify-content-center align-items-center treacking-experience">
            <Swiper
              {...verticleswiper2}
              spaceBetween={0}
              direction={isHorizontal ? "vertical" : "horizontal"}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              navigation
              onSlideChange={() => console.log("slide change")}
              className="swiper_ver_sliderr"
            >
              {trackingData.map((item, index) => (
                <SwiperSlide className="ver_sliderdiv" key={index}>
                  <div className="ver_parent">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item?.image[0]?.src}`}
                      className="object-fit-cover ver_slider"
                      style={{ borderRadius: "15px" }}
                      onClick={() => setSelectedImage(item)}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        if (target) {
                          target.src = "path/to/Noimg";
                        }
                      }}
                    />
                    <div className="v_inner_txt">
                      <span className="v_inner_txt2">{item.title}</span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      {inteData.length > 0 && (
        <section className="container mt-5 international-departure-section ">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center">
              <h3 className="for_departure ">
                <span className="for_departure2">INTERNATIONAL</span> DEPARTURES
              </h3>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 container">
              <div className="exploremore ">
                <span>
                  <Link
                    to="/international-departure"
                    onClick={handleclick}
                    className="exploremore_link"
                    style={{ textAlign: "right" }}
                  >
                    <span
                      style={{
                        borderBottom: "2.17px solid rgba(255, 169, 35, 1)",
                      }}
                      className="forhovereffect"
                    >
                      Explore More
                    </span>
                  </Link>
                </span>
                <span style={{ color: "#FFA923" }}>
                  <i className="fa-solid fa-arrow-right mx-2"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="container mt-4 ">
            <div className="row row-cols-2 row-cols-xl-4 row-cols-lg-3 row-cols-md-2">
              {inteData.slice(0, 4).map((item: any, index: number) => (
                <div
                  className="col d-flex justify-content-center p-3 object-fit-cover small-international"
                  key={index}
                  onClick={() => {
                    const id = item._id;
                    const slugUrl = item?.title
                      ?.toLowerCase()
                      .replace(/\s+/g, "-")
                      .replace(/\//g, "-")
                      .replace(/\((.*?)\)/g, "$1");
                    navigate(`/tripdetails/${slugUrl}`, {
                      state: id,
                    });
                  }}
                >
                  <div className="card card border-0 card-img-top-international position-relative">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item?.tour_image[0]?.src}`}
                      className="card-img-top-international International_img"
                      alt="..."
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        if (target) {
                          target.src = Noimg;
                        }
                      }}
                    />
                    <div className="inter_pkg">
                      <div className="for_weekandcontent ">
                        <p className="_hrr my-1">{item?.title}</p>
                        <p className="_hrprice my-1">
                          ₹{formatTotalAmount(item?.packageType_price)}
                        </p>
                      </div>
                      <button className="for_packbutton mt-1">
                        {item.nightorday} Night / {item.nightorday + 1} Days
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {backpacData.length > 0 && (
        <div className="conatiner ">
          <div className="backpaking_box">
            <div className=" backpacking-pink "></div>
            <div className="backpacking-light-yellow"></div>
            <div className="container ">
              <Link to="/backpackingtrips" className="backpacking-link">
                <p className=" backpacking-text">
                  <span>BACKPACKING </span>{" "}
                  <span className="backpacking-trip-text">TRIPS</span>{" "}
                </p>
              </Link>
              <div className="swiper-div">
                <Swiper
                  {...swiperParams}
                  pagination={{
                    clickable: true,
                  }}
                  style={{ padding: "0px 0px" }}
                  modules={[Pagination, Autoplay]}
                  className=""
                >
                  {backpacData.map((item: any, index: number) => (
                    <SwiperSlide
                      onClick={() => {
                        const id = item._id;
                        const slugUrl = item?.title

                          ?.toLowerCase()
                          .replace(/\s+/g, "-")
                          .replace(/\//g, "-")
                          .replace(/\((.*?)\)/g, "$1");
                        navigate(`/tripdetails/${slugUrl}`, {
                          state: id,
                        });
                      }}
                      style={{ padding: "0px 5px 30px 0px  ", margin: "1px" }}
                    >
                      <Card className="carddiv" key={index}>
                        <Card.Img
                          src={`${process.env.REACT_APP_API_URL}/${
                            (item?.tour_image[0] as any)?.src
                          }`}
                          className="bckping_img"
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            if (target) {
                              target.src = Noimg;
                            }
                          }}
                        />
                        <Card.Body className="d-flex flex-column align-items-center justify-content-around">
                          <p className="delhitrip">
                            {" "}
                            {item.title.length > 17
                              ? `${item.title.slice(0, 17)}...`
                              : item.title}
                          </p>

                          <Card.Title className="night">
                            {item.nightorday} Night / {item.nightorday + 1} Days
                          </Card.Title>
                          <Card.Title className="rupee">
                            ₹{formatTotalAmount(item?.packageType_price)}
                          </Card.Title>
                          <button className="backpakin-btan-divi">
                            BOOK NOW
                          </button>
                        </Card.Body>
                      </Card>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      )}

      {weekData.length > 0 && (
        <div className="container">
          <section className="mt-4">
            <Link
              to="/WeekendTrips"
              className="personalized-link"
              style={{ textDecorationColor: "black" }}
            >
              <h3 className="text-center weekend weekend-section">
                <span>WEEKEND</span>{" "}
                <span className="weekendss_trip">TRIPS</span>
              </h3>
            </Link>

            <div className="row row-cols-2 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 g-3 mt-4 small-weekend">
              {weekData.slice(0, 4).map((item: any, index: number) => (
                <div
                  className="col d-flex justify-content-center"
                  onClick={() => {
                    const id = item._id;
                    const slugUrl = item?.title
                      ?.toLowerCase()
                      .replace(/\s+/g, "-")
                      .replace(/\//g, "-")
                      .replace(/\((.*?)\)/g, "$1");
                    navigate(`/tripdetails/${slugUrl}`, {
                      state: id,
                    });
                  }}
                >
                  <div className="card border-0 card-img-top-weekend">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item?.tour_image[0]?.src}`}
                      className="img-fluid card-img-top-weekend Weekend_img"
                      alt="..."
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        if (target) {
                          target.src = Noimg;
                        }
                      }}
                    />
                    <div className="for_weekandcontent">
                      <p>{item.title}</p>
                      <p>₹{formatTotalAmount(item?.packageType_price)}</p>
                    </div>
                    <button className="for_packbutton">
                      {item.nightorday} Night / {item.nightorday + 1} Days
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      )}

      <section className="group-trips mb-5 mt-5">
        <div
          className="p-3 text-center for_small_devicegroup"
          style={{ backgroundColor: "#FFEAC9" }}
        >
          <h3 className="for_grouptrip">
            <span style={{ color: "#DC1B5E", fontWeight: "700" }}>GROUP</span>{" "}
            <span>TRIPS</span>
          </h3>
          <p className="Stop_expoloring">NEVER STOP EXPLORING THE WORLD</p>
        </div>

        <div className="container mt-5 for_group_tripcard">
          <div className="row row-cols-2 row-cols-md-4 g-3 mt-4">
            <div className="col ">
              <div className="card card border-0">
                <img
                  src={solotrip}
                  className="card-img-top img-fluid"
                  alt="..."
                  style={{
                    maxHeight: "400px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <div
                  className="for_content_top"
                  style={{ backgroundColor: "#00000040" }}
                >
                  <h4 className="text-center tips_uper">Solo Trips</h4>
                </div>
                <div className="for_content_inner3">
                  <Button
                    className="for_btn_enquiry"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                  >
                    ENQUIRY NOW
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabIndex={1}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                      Enquiry Form
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="row d-flex px-3 mt-3">
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Name*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Destination*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Destination"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Number of travellers*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Number Of Travellers"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Mobile Number*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ); // Allow only numbers
                          }}
                          placeholder="Enter Your Mobile Number"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Email ID"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          From Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          To Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-primary m-auto "
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      style={{ background: "rgba(255, 169, 35, 1)" }}
                      onClick={() => gtag_report_conversion()}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card border-0 ">
                <img
                  src={Familytrip}
                  className="card-img-top img-fluid"
                  alt="..."
                  style={{
                    maxHeight: "400px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <div
                  className="for_content_top"
                  style={{ backgroundColor: "#00000040" }}
                >
                  <h4 className="text-center tips_uper">Family Trips</h4>
                </div>
                <div className="for_content_inner3">
                  <Button
                    className="for_btn_enquiry"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                  >
                    ENQUIRY NOW
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabIndex={1}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                      Enquiry Form
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="row d-flex px-3 mt-3">
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Name*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Destination*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Destination"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Number of travellers*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Number Of Travellers"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Mobile Number*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          placeholder="Enter Your Mobile Number"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Email ID"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          From Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          To Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-primary m-auto "
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      style={{ background: "rgba(255, 169, 35, 1)" }}
                      onClick={() => gtag_report_conversion()}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card card border-0 ">
                <img
                  src={corporate}
                  className="card-img-top img-fluid"
                  alt="..."
                  style={{
                    maxHeight: "400px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <div
                  className="for_content_top"
                  style={{ backgroundColor: "#00000040" }}
                >
                  <h4 className="text-center tips_uper">Corporate Trips</h4>
                </div>
                <div className="for_content_inner3">
                  <Button
                    className="for_btn_enquiry"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                  >
                    ENQUIRY NOW
                  </Button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card card border-0">
                <img
                  src={collegetrip}
                  className="card-img-top img-fluid"
                  alt="..."
                  style={{
                    maxHeight: "400px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <div
                  className="for_content_top"
                  style={{ backgroundColor: "#00000040" }}
                >
                  <h4 className="text-center tips_uper">College Trips</h4>
                </div>
                <div className="for_content_inner3">
                  <Button
                    className="for_btn_enquiry"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                  >
                    ENQUIRY NOW
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabIndex={1}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                      Enquiry Form
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="row d-flex px-3 mt-3">
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Name*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Destination*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Destination"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Number of travellers*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Number Of Travellers"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Mobile Number*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          placeholder="Enter Your Mobile Number"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Email ID"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          From Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          To Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-primary m-auto "
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      style={{ background: "rgba(255, 169, 35, 1)" }}
                      onClick={() => gtag_report_conversion()}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabIndex={1}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                      Enquiry Form
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="row d-flex px-3 mt-3">
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Name*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Destination*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Destination"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Number of travellers*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Number Of Travellers"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Mobile Number*
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Mobile Number"
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Email ID"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          From Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          To Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-primary m-auto "
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      style={{ background: "rgba(255, 169, 35, 1)" }}
                      onClick={() => gtag_report_conversion()}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <hr
          className="for_hrline"
          id="personalizedSection"
          style={{ opacity: "1" }}
        />
      </div>
      <div className="container">
        {personalizedData.length > 0 && (
          <section className="mb-5">
            <h2
              style={{ fontWeight: "700" }}
              className="for_personalizedtrips text-center mt-5"
            >
              <div
                // to="/personalized"
                className="personalized-link"
                style={{ textDecorationColor: "black" }}
              >
                <span style={{ color: "#DC1B5E" }}>PERSONALIZED </span>
                <span className="text-dark"> TRIPS</span>
              </div>
            </h2>

            <h5 className="for_secondpersonalized mt-3 mb-3">
              Didn't find what you had in mind? Personalize one just for you!
              Explore these trending destinations for customized trips!
            </h5>
            <div>
              <div className="row row-cols-2 row-cols-xl-4  row-cols-lg-3  row-cols-md-2 g-3 mt-3">
                {personalizedData.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="col d-flex justify-content-center align-items-center"
                    onClick={() => {
                      const slugUrl = item.title
                        ?.toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/\//g, "-")
                        .replace(/\((.*?)\)/g, "$1");
                      navigate(`/featured-package/${slugUrl}`, {
                        state: {
                          itemid: item._id,
                          run: true,
                          // source: "featuredTour",
                        },
                      });
                    }}
                  >
                    <div className="card border-0">
                      <div className="for_cards_personalized">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item?.image?.[0]?.src}`}
                          className="card-img-top-personalized for_personalized"
                          alt="..."
                        />
                        <div
                          className="for_personalized_under"
                          style={{
                            position: "absolute",
                            top: "0%",
                            left: "0%",
                            width: "80%",
                          }}
                        >
                          <span
                            className="p-16 w-full for_underdrop"
                            style={{
                              clipPath:
                                "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)",
                              position: "relative",
                              display: "inline-block",
                              background:
                                index % 2 === 0
                                  ? "rgba(255, 169, 35, 1)"
                                  : "#dc1b5e",
                            }}
                          >
                            {item.title}
                            <span
                              style={{
                                display: "inline-block",
                                width: "1rem",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </div>

      <section className="backgroundimg mt-4">
        <div className="container">
          <div
            className="text-center backgroundcontentunder"
            style={{ background: " rgba(255, 234, 201, 0.5)" }}
          >
            <h3 className="for_h3line">
              Didn’t Find Journey Plans and Offers as Expected?
            </h3>
            <hr className="w-25 m-auto didnothr mt-4" />
            <p className="for_here">Here your misery ends </p>
            <Link to="/contactus">
              <button className="connectbtn">CONNECT NOW</button>
            </Link>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="container">
          <section className="mb-5 mt-5">
            <div className="container">
              <div className="text_line w-100 d-flex justify-content-between align-items-center">
                <hr className="for_hrline2" />
                <p className="text-center test_text">TESTIMONIALS</p>
                <hr className="for_hrline2" />
              </div>

              <Swiper
                {...swipertestimonial}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper mt-5 for_mobile_viewswiper"
              >
                <SwiperSlide>
                  <div className="testparent">
                    <img src={subtract22} className="img-fluid testiimg" />
                    <div className="testchild ">
                      <p className="testchildcontent">
                        I have travelled through glabol to Vietnam trip and it
                        was the best trip I ever had. Everything was planned
                        well and stays were beautiful and all the rooms were
                        having good views.
                      </p>
                      <div className="secondtestchild d-flex mt-2">
                        <div>
                          <img
                            src={chinmayee}
                            className="img-fluid test-prof"
                          />
                        </div>
                        <div className="mx-3 test-prof-text">
                          <h5 className="txt-1">Chinmayee kukde</h5>
                          {/* <p className="txt-2">Lorem ipsum </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testparent">
                    <img src={subtract22} className="img-fluid testiimg" />
                    <div className="testchild ">
                      <p className="testchildcontent">
                        My Ladakh motorcycle tour with Glabol Tours was an
                        unforgettable adventure. From expert guides to stunning
                        landscapes, every moment was exceptional. It’s
                        affordable and recommend for a thrilling trip
                      </p>
                      <div className="secondtestchild d-flex mt-2">
                        <div>
                          <img src={anil} className="img-fluid test-prof" />
                        </div>
                        <div className="mx-3 test-prof-text">
                          <h5 className="txt-1">Anil Charanjeett</h5>
                          <p className="txt-2">Bollywood Actor </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testparent">
                    <img src={subtract22} className="img-fluid testiimg" />
                    <div className="testchild">
                      <p className="testchildcontent">
                        {isExpanded
                          ? `Just got back from an epic adventure biking the Spiti
             Valley circuit, and I have to give a huge shoutout to
             the amazing team at Glabol Expedition Pvt Ltd for making
             it unforgettable! Their local expertise was invaluable,
             from navigating the challenging terrain to uncovering
             hidden gems along the way. The support crew was
             top-notch, always there to lend a hand with a smile.
             They took care of everything, allowing me to truly
             immerse myself in the breathtaking landscapes and rich
             culture of the Himalayas. If you're looking for a
             seamless and unforgettable Spiti Valley biking
             experience, look no further!`
                          : `Just got back from an epic adventure biking the Spiti
             Valley circuit, and I have to give a huge shoutout to
             the amazing team at Glabol Expedition Pvt Ltd...`}
                      </p>

                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => setIsExpanded(!isExpanded)}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </button>

                      <div className="secondtestchild d-flex align-items-center mt-3">
                        <div>
                          <img
                            src={suraj}
                            className="img-fluid test-prof"
                            alt="Suraj Kumar H U"
                          />
                        </div>
                        <div className="mx-3 test-prof-text">
                          <h5 className="txt-1">Suraj Kumar H U</h5>
                          {/* <p className="txt-2">Lorem ipsum </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        </div>
      </div>
      <hr className="for_hrline" />
      <div className="container">
        <section className="mb-5 mt-5">
          <div className="row">
            <div className=" d-flex justify-content-between  flex-column col-lg-5 col-md-6 col-sm-12">
              <h2 className="chooseus">WHY CHOOSE US?</h2>

              <div className="row d-flex mt-1">
                <ul className="">
                  <li className=" m-0 list-group-item d-flex justify-content-between align-items-start">
                    <span className="badge bg-warning text-dark rounded-pill me-4">
                      1
                    </span>
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <h5 className="for_travelpackage">
                          Comprehensive Travel Packages
                        </h5>
                      </div>
                      <p className="for_aboutoffer">
                        Glabol India offers end-to-end travel packages
                        International destinations.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="row d-flex mt-2">
                <ul className=" ">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <span className="badge bg-warning text-dark rounded-pill me-4">
                      2
                    </span>
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <h5 className="for_travelpackage">
                          Group-Centric Itineraries:
                        </h5>
                      </div>
                      <p className="for_aboutoffer">
                        They specialize in crafting tailored travel itineraries
                        that promote group travel, bringing together like-minded
                        individuals shared experiences.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="row d-flex mt-2">
                <ul className=" ">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <span className="badge bg-warning text-dark  rounded-pill me-4">
                      3
                    </span>
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <h5 className="for_travelpackage">
                          Diverse Adventure Services:
                        </h5>
                      </div>
                      <p className="for_aboutoffer">
                        Glabol India provides a range of adventure services,
                        including road trips and trekking expeditions, catering
                        to various travel interests.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="row d-flex mt-2">
                <ul className="m-0 ">
                  <li className=" m-0 list-group-item d-flex justify-content-between align-items-start">
                    <span className="badge bg-warning text-dark rounded-pill me-4">
                      4
                    </span>
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <h5 className="for_travelpackage">Corporate Travel:</h5>
                      </div>
                      <p className="for_aboutoffer">
                        They also offer specialized corporate trips designed to
                        enhance team bonding and relaxation in unique settings.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 col-sm-12">
              <div className="for_backimgparent">
                <img src={Backyellow} className="img-fluid" alt="Background" />
                <div className="for_backimgchild">
                  <img src={News} className="img-fluid " alt="News" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
