import Travelconcept from "../../assets/images/twoman.jpg";
import bgpink from "../../assets/images/bgpink.png";
import bgpinkchild from "../../assets/images/ffffffffffff.png";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";

function Career() {
  return (
    <>
      <RedirectUrlAfterLogin />
      <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}>
        <img src={Travelconcept} alt="About Us Page" className="bannerimg" />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: "center",
            width: "90%",
          }}
        >
          <h2 style={{ fontSize: "4vw" }} className="for_aboutus">
            Career{" "}
          </h2>
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: "5vw" }}>
        <div className="row align-items-start ">
          <div className="col-lg-6 col-md-12">
            <div
              style={{
                position: "relative",
                maxWidth: "100%",
                height: "auto",
              }}
            >
              <div
                style={{
                  position: "relative",
                  maxWidth: "90%",
                  height: "auto",
                }}
                className="for_mainbgpink"
              >
                <img
                  src={bgpink}
                  alt="Rectangle"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  className="for_bgpink"
                />
              </div>
              <div>
                <img
                  src={bgpinkchild}
                  alt="aboutusp"
                  style={{
                    position: "absolute",
                    top: "54.2%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    textAlign: "center",
                    width: "90%",
                  }}
                  className="for_bgpinkchild"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 h-100">
            <div>
              <h3
                style={{
                  textAlign: "justify",
                  fontWeight: "bold",
                }}
                className="for_loremhead"
              >
                About Us:
              </h3>
              <p style={{ textAlign: "justify" }} className="for_loremtext">
                Glabol India is a travel company offering comprehensive packages
                for domestic and international destinations, including road
                trips, trekking adventures, corporate outings, and customized
                tours. We specialize in curating exceptional group travel
                experiences, providing high-quality accommodations, guided
                sightseeing, and competitive pricing. Our goal is to ensure a
                seamless and memorable journey for our clients.
              </p>
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="container py-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-12 h-100">
            <div className="row">
              <div className="col-6">
                <h3 className="fw-bold text-center mb-4">
                  Roles and Responsibilities of Operational Manager at Glabol
                </h3>
                <ul className="mt-4">
                  <li className="mb-3">
                    <strong>Operational Efficiency:</strong> Oversee day-to-day
                    travel operations, ensuring smooth execution of tours,
                    bookings, and client services.
                  </li>
                  <li className="mb-3">
                    <strong>Team Leadership:</strong> Manage and mentor staff,
                    from travel agents to logistics teams, fostering a
                    high-performance culture.
                  </li>
                  <li className="mb-3">
                    <strong>Vendor Management:</strong> Collaborate with travel
                    suppliers (hotels, transport, guides) to negotiate contracts
                    and maintain strong relationships.
                  </li>
                  <li className="mb-3">
                    <strong>Budget & Cost Control:</strong> Monitor operational
                    budgets, optimize costs, and improve profitability.
                  </li>
                  <li className="mb-3">
                    <strong>Customer Experience:</strong> Ensure exceptional
                    customer service, resolve issues quickly, and maintain high
                    satisfaction levels.
                  </li>
                  <li className="mb-3">
                    <strong>Process Improvement:</strong> Identify areas for
                    operational improvements and implement solutions to enhance
                    efficiency and service quality.
                  </li>
                  <li className="mb-3">
                    <strong>Compliance & Safety:</strong> Ensure all operations
                    comply with legal regulations and safety standards.
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <h3 className="fw-bold text-center mb-4">Requirements:</h3>
                <ul className="mt-4">
                  <li className="mb-3">
                    <strong>Experience:</strong> 3+ years in operations
                    management, preferably in the travel, hospitality, or
                    related industry.
                  </li>
                  <li className="mb-3">
                    <strong>Education:</strong> Bachelor's degree in Business
                    Administration, Travel & Tourism, or a related field.
                  </li>
                  <li className="mb-3">
                    <strong>Leadership Skills:</strong> Proven ability to lead
                    and manage teams effectively.
                  </li>
                  <li className="mb-3">
                    <strong>Communication:</strong> Strong verbal and written
                    communication skills for coordinating with internal teams
                    and external partners.
                  </li>
                  <li className="mb-3">
                    <strong>Problem-Solving:</strong> Excellent analytical and
                    problem-solving abilities to handle operational challenges.
                  </li>
                  <li className="mb-3">
                    <strong>Customer-Centric Mindset:</strong> Commitment to
                    delivering outstanding customer experiences.
                  </li>
                  <li className="mb-3">
                    <strong>Organizational Skills:</strong> Strong attention to
                    detail and multitasking ability to manage various
                    operational functions simultaneously.
                  </li>
                  <li className="mb-3">
                    <strong>Flexibility:</strong> Ability to adapt to changing
                    environments, including travel demands or unpredictable
                    situations.
                  </li>
                  <li className="mb-3">
                    <strong>Compliance Knowledge:</strong> Understanding of
                    industry regulations and safety standards.
                  </li>
                </ul>
              </div>{" "}
              <p>
                <strong>Our Partnership to Protect You:</strong> Glabol will
                never ask you for money at any stage of your application
                process, and you won't need to pay anything to continue with
                your application. Make sure you only communicate with authorized
                Glabol recruiters.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
